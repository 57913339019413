import { httpClient } from "@/app/shared/shared-services/httpClient";
import { environment } from "@/environment";

const URL = environment.apiBaseURL;

export default {
  async fetchAllJabies(query) {
    const url = `${URL}jabies${query}`;
    return (await httpClient.get(url)).data.data;
  },
  async fetchJabieFinishedOrders(query) {
    const url = `${URL}jabies${query}`;
    return (await httpClient.get(url)).data.data;
  },
  async fetchJabieCurrentOrders(query) {
    const url = `${URL}jabies${query}`;
    return (await httpClient.get(url)).data.data;
  },
  async fetchAllJabiesDistricts() {
    const url = `${URL}districts/active-free`;
    return (await httpClient.get(url)).data.data;
  },
  async checkJabiActivation(jabiId) {
    const url = `${URL}jabies/${jabiId}/check-deactivate`;
    return await httpClient.get(url);
  },
  async createJabi(jabi) {
    const url = `${URL}jabies`;
    return await httpClient.post(url, jabi);
  },
  async updateJabi(jabi) {
    const url = `${URL}jabies/${jabi.id}`;
    return await httpClient.put(url, jabi);
  },
  exportCurrentOrdersToExcel(id) {
    const url = `${URL}export-current-to-excel${id}`;
    return httpClient
      .get(url, { responseType: "arraybuffer" })
      .then((res) => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "currentOrders.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch((error) => console.log(error));
  },
  exportFinishedOrdersToExcel(id) {
    const url = `${URL}export-finished-to-excel${id}`;
    return httpClient
      .get(url, { responseType: "arraybuffer" })
      .then((res) => {
        let fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "finishedOrders.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch((error) => console.log(error));
  },
};
