import jabiesRepository from "./jabiesRepository";


export const repositories = {
  jabiesRepository,
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
