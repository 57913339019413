<template>
  <div>
    finishedOrders

    <!-- <div>{{ $route.meta($route).breadcrumb }}</div> -->

    <ag-table
      :data-source-api="getAll"
      :grid-options="gridOptions"
      :permissions="permissions"
      :rtl="false"
      @emitExportToExcel="exportToExcel"
    />


  </div>
</template>

<script>
import AgTable from '../../shared/shared-components/ag-grid/AgTable';
import {RepositoryFactory} from '../jabi-repositories/jabiRepositoryFactory';

const jabiRepo = RepositoryFactory.get('jabiesRepository');
export default {
  name: "finishedOrders",
  components: {
    AgTable
  },
  data() {
    return {
      permissions: {}
    };
  },
  methods: {
        // createColDefs() {
    //     let self = this;
    //     return [
    //       {
    //         headerName: 'المعرف',
    //         field: 'id',
    //         suppressSizeToFit: true,
    //         checkboxSelection: true,
    //         filter: 'agNumberColumnFilter',
    //         width: 100,
    //         sortable: false
    //       },
    //       {
    //         headerName: 'الاسم',
    //         field: 'full_name',
    //         filter: 'agTextColumnFilter',
    //         width: 100,
    //         sortable: false
    //       },
    //       {
    //         headerName: 'الرقم',
    //         field: 'mobile',
    //         filter: 'agTextColumnFilter',
    //         width: 100,
    //         sortable: false
    //       },
    //       {
    //         headerName: 'المنطقة',
    //           valueGetter:
    //             function getObject(params) {
    //               return params.data.districts.map(x => x.name).join('-');
    //             },
    //         field: 'districts',
    //         filter: 'agTextColumnFilter',
    //         sortable: false
    //       },
    //     ];
    // },
    // getAll(query) {
    //   return jabiRepo.fetchJabieFinishedOrders(query).then((response => response));
    // },
  },
  created() {
    // this.gridOptions = {};
    // this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    // this.gridApi = this.gridOptions.api;
  }
};
</script>

